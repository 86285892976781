import React from 'react';
import Home from '../components/layout/Home';
import Hero from '../components/Hero';
import PVD from "../images/PVD.jpg";
import LogoIconFull from '../svg/LogoFull';
import { Helmet } from "react-helmet";
import ShareImg from "../images/share.png";

export default () => (
  <Home>
    <Hero
      coverImage={ PVD } 
      fullHeight 
      primaryContent={
        <div class="flex">
          <div>
            <div class="mb-4 text-5xl"> Responsiveness matters </div>
            <div class="mb-4 text-5xl"> Experience matters </div>
            <div class="mb-4 text-5xl"> Results matter </div>
          </div>
        </div>
      }
      secondaryContent={
        <div class="max-w-sm mx-auto">
          <LogoIconFull/>
        </div>
      }
    />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Home | Hollis Meddings Group</title>
      <meta name="description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta property="og:title" content="Hollis Meddings Group"/>
      <meta property="og:description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta property="og:image" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:url" content="https://www.hollismeddings.com"/>
      <meta name="twitter:card" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:site_name" content="Hollis Meddings Group"/>
      <meta name="twitter:image:alt" content="Hollis Meddings Group"/>
      <body class="md:overflow-hidden" />
    </Helmet>
  </Home>
);
