import React from 'react';
import Header from './Header';

const Hero = ({ children }) => {
  return (
    <>
      <Header />
      <main className="text-gray-900 overflow-hidden">{children}</main>
    </>
  );
};

export default Hero;
